<template>
  <div class="wrapper" v-if="isLoaded">
    <div v-if="usedCarInfo == null">
      <h1>Carro não encontrado!</h1>
    </div>
    <div v-if="usedCarInfo != null" class="fluid-element">
      <div class="hero-grid">
        <div class="left-slot">
          <div class="main-image is-used">
            <div
              @click="toogleFavorite"
              :class="isFavorite ? 'favorite-range on' : 'favorite-range'"
            >
              <div
                :class="
                  isFavorite
                    ? 'favorite-button-range on'
                    : 'favorite-button-range'
                "
              >
                <Heart />
              </div>
            </div>
            <img :src="usedCarInfo.mainImage" alt="" />
          </div>
          <div style="width: 90%; display: flex; margin: auto">
            <Swiper
              isUsed
              v-if="usedCarInfo.alternativeImage"
              :data="usedCarInfo.alternativeImage"
              @alternativeClick="alternativeClick"
            />
          </div>
        </div>
        <div class="right-slot">
          <div class="upper-section">
            <div class="hero-economic">
              <div>
                <div class="hero-heading-row">
                  <h1 class="hero-heading">
                    <span>{{ usedCarInfo.title }}</span>
                  </h1>
                  <div class="elm-desktop">
                    <h2 class="hero-interested">
                      <span>{{ usedCarInfo.viewers }} interessados</span
                      ><span> nas últimas 24h</span>
                    </h2>
                  </div>
                </div>
                <h2 class="hero-sub-heading">
                  <span>{{ usedCarInfo.version }}</span>
                </h2>
              </div>
              <p v-if="usedCarInfo.kms" class="hero-color">
                <span class="red-bullet"> &bull; </span
                ><span>{{ usedCarInfo.kms }} kms </span>
                <span class="red-bullet"> &bull; </span
                ><span>{{ usedCarInfo.fuelType }}</span>
                <span class="red-bullet"> &bull; </span
                ><span>{{ usedCarInfo.year }}</span>
              </p>
              <hr />
              <div class="hero-price-row">
                <div class="hero-price-details">
                  <div class="legal-row">
                    <div class="info-legal">
                      <img :src="require('@/assets/info.svg')" />
                      <p class="info-text" v-html="usedCarInfo.legalInfo"></p>
                    </div>
                    <p class="hero-price-month">
                      {{ usedCarInfo.formattedFee }}
                    </p>
                  </div>

                  <p class="hero-price-taeg">TAEG: {{ usedCarInfo.taeg }}</p>
                </div>
                <div class="hero-price-details">
                  <div class="range-price-full-pvp">PVP</div>
                  <div class="legal-row">
                    <div class="info-legal">
                      <img :src="require('@/assets/info.svg')" />
                      <p
                        class="info-text-right"
                        v-html="usedCarInfo.legalInfo"
                      ></p>
                    </div>
                    <div class="hero-price-full">
                      {{ formatPriceNumber(finalPrice, "2", "€") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="hero-price-info">
                <p class="hero-price-label">
                  Entrada inicial:
                  <span class="hero-price-value">{{
                    usedCarInfo.initEntrance
                  }}</span>
                </p>
                <p class="hero-price-label">
                  Prazo:
                  <span class="hero-price-value"
                    >{{ usedCarInfo.nrPayments }} meses</span
                  >
                </p>
                <p class="hero-price-label">
                  Montante financiado:
                  <span class="hero-price-value">{{
                    usedCarInfo.capitalToFinance
                  }}</span>
                </p>
              </div>
              <div v-if="legal">
                <p class="hero-title">Notas Legais</p>
                <p class="legal" v-html="usedCarInfo.legalInfo"></p>
              </div>
            </div>
            <div class="elm-mobile">
              <h2 class="hero-interested">
                <span>{{ usedCarInfo.viewers }} interessados</span
                ><span> nas últimas 24h</span>
              </h2>
            </div>
            <ButtonsWrapper
              :data="newCarCta"
              btnClass="four-btn "
              wrapperClass="four-button hero-btn row  "
              @action="action($event)"
            />
          </div>
        </div>

        <div class="lower-section">
          <div class="hero-accordion-container">
            <div class="hero-accordion-item">
              <button
                :class="toggleSpec ? 'is-clicked accordion' : 'accordion'"
                @click="toggle(0)"
              >
                Especificações
                <Arrow class="arrow" :class="{ clicked: toggleSpec }" />
              </button>
              <Transition name="panel">
                <div class="panel" v-if="toggleSpec">
                  <SpecList :data="usedCarInfo.specifications" />
                </div>
              </Transition>
            </div>
            <div class="hero-accordion-item">
              <button
                :class="toggleEquip ? 'is-clicked accordion' : 'accordion'"
                @click="toggle(2)"
              >
                Equipamentos
                <Arrow class="arrow" :class="{ clicked: toggleEquip }" />
              </button>
              <Transition name="panel">
                <div class="panel" v-if="toggleEquip">
                  <EquipList :data="usedCarInfo.equipment" />
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Section
      v-if="toggleOSDisplay && usedCarInfo"
      id="onlineSelling"
      ref="onlineSelling"
    >
      <div class="title-area">
        <p class="form-title">
          Reserve Online o seu novo Toyota {{ usedCarInfo.model }}
        </p>
        <p class="simulator-text" v-html="onlineBookingText"></p>
      </div>
      <FormOS
        :imageUrl="usedCarInfo.mainImage"
        class="form-fw"
        :data="formOS"
        :car="{
          model: usedCarInfo.model,
          desc: usedCarInfo.version,
          fee: usedCarInfo.formattedFee,
          taeg: usedCarInfo.taeg,
          price: usedCarInfo.formattedPrice,
          initEntrance: usedCarInfo.initEntrance,
          nrPayments: usedCarInfo.nrPayments,
          capitalToFinance: usedCarInfo.capitalToFinance,
          legalInfo: usedCarInfo.legalInfo,
        }"
        id="online-selling"
        @submit="formOSSubmit"
      />
      <div class="legal-money">
        <span
          >Realize o seu pagamento de forma segura através dos seguintes métodos
          de pagamento:
        </span>
        <img src="../assets/cc-mb-square.png" alt="" />
      </div>
    </Section>
    <Section v-if="toggleSimulatorDisplay && usedCarInfo" id="simulator">
      <div class="title-area">
        <div class="align-left">
          <p class="form-title">
            Simulador de Financiamento {{ isLexus ? "Lexus" : "Toyota" }}
          </p>
          <p class="simulator-text" v-html="simulatorText"></p>
        </div>
      </div>

      <Simulator
        :finalPrice="finalPrice"
        :initValues="{ ...usedCarInfo }"
        :vin="usedCarInfo.vin"
        :creditTypes="usedCarInfo.creditTypes"
        @action="checkValues"
      />
    </Section>

    <Section v-if="toggleTestDriveDisplay" id="testDrive" ref="testDrive">
      <div class="title-area">
        <p class="form-title">
          Agende de forma online o Test-Drive do seu {{ usedCarInfo.model }}
        </p>
        <p class="simulator-text" v-html="testDriveText"></p>
      </div>
      <FormTS
        class="form-fw"
        :data="formTS"
        carType="usado"
        :model="usedCarInfo.model"
        :dealer="dealerShip.objectId"
      />
    </Section>
    <div class="fluid-element">
      <Section class="grey-background">
        <h1 class="modal-title">LOCALIZAÇÃO DA VIATURA</h1>
        <div class="dealer-panel">
          <div class="img-container">
            <img :src="dealerShip.imageInstallation" alt="" />
          </div>
          <div class="info-container">
            <div class="dealer-name">
              {{ dealerShip.designation }} - {{ dealerShip.postalCodeDesig }}
            </div>
            <div class="dealer-adress">
              {{ dealerShip.address }}, {{ dealerShip.numberDoor }},
              {{ dealerShip.postalCode4 }}-{{ dealerShip.postalCode3 }}
            </div>
            <div class="dealer-desig">
              {{ dealerShip.postalCodeDesig }}
            </div>
            <div class="dealer-phone" v-if="dealerShip.contacts.length > 0">
              Telefone:

              <a
                v-if="dealerShip.contacts[0].contactPersons.length > 0"
                :href="
                  'tel:' +
                  dealerShip.contacts[0].contactPersons[0].contactList[0].value
                "
                >{{
                  dealerShip.contacts[0].contactPersons[0].contactList.find(
                    (el) => el.designation == "Telefone"
                  ).value
                }}</a
              >
            </div>
            <div class="dealer-email" v-if="dealerShip.contacts.length > 0">
              E-mail:
              <span v-if="dealerShip.contacts[0].contactPersons.length > 0">
                {{
                  dealerShip.contacts[0].contactPersons[0].contactList.find(
                    (el) => el.designation == "E-mail"
                  ).value
                }}
              </span>
            </div>
            <div class="anchor-wrapper">
              <a
                class="dealer-anchor"
                @click="$router.push('/instalacoes/' + dealerShip.objectId)"
                >Ver Instalação
              </a>
              <a class="test-drive-anchor" @click="ctaAction('1')"
                >Agendar Test-Drive
              </a>
            </div>
          </div>
        </div>
      </Section>
    </div>

    <Section>
      <h1 class="modal-title">EXTENSÃO DE GARANTIA PLUS</h1>

      <div class="garantia-panel">
        <div
          v-for="(contract, i) in usedCarInfo.warranty"
          class="contract-contain"
          :key="i"
        >
          <div class="contract-check">
            <div>
              <input
                class="custom-checkbox"
                type="checkbox"
                name="warranty"
                @click="warrantyClick(contract)"
                :id="contract.label"
              />
              <label class="checkbox-label" :for="contract.label"
                >Quero subscrever a {{ contract.label }}</label
              >
            </div>
            <div class="contract-price">
              {{ formatPriceNumber(contract.cost, "2", "€") }}
            </div>
          </div>
          <div class="contract-desc">{{ contract.description }}</div>
          <hr v-if="i + 1 < usedCarInfo.warranty.length" />
        </div>
      </div>
    </Section>
    <Section v-if="usedCarInfo.contracts.length > 0">
      <h1 class="modal-title">CONTRATO DE MANUTENÇÃO</h1>
      <div class="hero-accordion-container">
        <div
          class="hero-accordion-item"
          v-for="(contract, i) in usedCarInfo.contracts"
          :key="i"
        >
          <button
            :class="contracts[i] ? 'is-clicked accordion' : 'accordion'"
            @click="toggleContract(i)"
          >
            {{ i }} anos
            <Arrow class="arrow" :class="{ clicked: contracts[i] }" />
          </button>
          <Transition name="panel">
            <div :class="contracts[i] ? 'panel' : 'panel hidden'">
              <div
                class="contract-panel"
                v-for="(options, j) in contract"
                :key="j"
              >
                <div class="m-a">
                  <input
                    class="custom-checkbox"
                    type="checkbox"
                    @click="contractClick(options)"
                    name="contract"
                    :id="options.contractName"
                  />

                  <label class="checkbox-label" :for="options.contractName">{{
                    options.contractName
                  }}</label>
                </div>

                <div class="mt-0">{{ options.pvpText }}€</div>
              </div>
            </div>
          </Transition>
        </div>
        <div class="hero-accordion-item"></div>
      </div>
    </Section>
    <CTA isUsed @callAction="ctaAction" />
    <div id="toast">
      <span>{{ toastText }}</span> <span @click="closeToast()"><xIcon /></span>
    </div>
  </div>
</template>

<script>
import Simulator from "@/components/Simulator/Simulator.vue";
import FormOS from "@/components/Forms/FormOS.vue";
import Section from "@/components/Section/Section.vue";
import FormConfiguration from "@/data/FormsDev.json";
import EquipList from "@/components/RangeList/EquipList";
import SpecList from "@/components/RangeList/SpecList";
import Arrow from "@/assets/OpenArrow.vue";
import ButtonsWrapper from "@/components/Buttons/ButtonsWrapper.vue";
import Swiper from "@/components/Swiper/Swiper.vue";
import Heart from "@/assets/Heart.vue";
import { mapGetters } from "vuex";
import { formatPriceNumber } from "@/functions/general.js";
import CTA from "@/components/CTA/CTA.vue";
import xIcon from "@/assets/x.vue";

import { formOsSubmit } from "@/services/functions.js";
import FormTS from "../components/Forms/FormTS.vue";

export default {
  name: "UsedSingle",
  metaInfo() {},
  components: {
    Simulator,
    Section,
    FormOS,
    xIcon,
    Arrow,
    CTA,
    SpecList,
    EquipList,
    ButtonsWrapper,
    Heart,
    Swiper,
    FormTS,
  },
  data() {
    return {
      usedCarInfo: [],
      legal: false,
      isFavorite: false,
      toastMessage: "",
      newCarCta: [
        {
          buttonLink: "#onlineSelling",
          buttonLabel: "Reserva Online",
          btnClass: "primary-btn",
          anchor: true,
        },
        {
          buttonLink: "#simulator",
          buttonLabel: "Simulador de Financiamento",
          btnClass: "primary-btn",
          anchor: true,
        },
        {
          buttonLink: "/retake/2",
          buttonLabel: "Calcular Retoma",
          btnClass: "secondary-btn",
          anchor: false,
        },
        {
          buttonLink: "#testDrive",
          buttonLabel: "Marcar Test Drive",
          btnClass: "secondary-btn",
          anchor: true,
        },
      ],
      toggleOSDisplay: false,
      toggleSimulatorDisplay: true,
      toggleTestDriveDisplay: false,
      formOS: FormConfiguration.onlineSelling,
      formTS: FormConfiguration.testDriveUsados,
      usedCarInfoForm: [],
      toggleEquip: false,
      toggleSpec: true,
      isLoaded: false,
      warrantyPrice: 0,
      warrantyPopup: true,
      contractPrice: 0,
      contracts: [],
    };
  },
  computed: {
    isLexus() {
      return this.$store.state.isLexus;
    },
    toastText() {
      return this.toastMessage;
    },
    finalPrice() {
      let final =
        Number(this.usedCarInfo.price) +
        this.warrantyPrice +
        this.contractPrice;
      return final;
    },
    simulatorText() {
      return this.replaceBOVariable(
        this.$store.state.simulatorText,
        "{#modelCar}",
        this.usedCarInfo.model
      );
    },
    onlineBookingText() {
      return this.replaceBOVariable(
        this.$store.state.onlineBookingText,
        "{#modelCar}",
        this.usedCarInfo.model
      );
    },
    testDriveText() {
      return this.replaceBOVariable(
        this.$store.state.testDriveText,
        "{#modelCar}",
        this.usedCarInfo.model
      );
    },
    ...mapGetters("user", {
      user: "getUserData",
    }),
    dealerShip() {
      let dealer = this.$store.state.allInstallations.find(
        (el) => el.objectId == this.usedCarInfo.oidDealer
      );
      return dealer;
    },
  },
  beforeCreate() {
    this.$store
      .dispatch("getCar", {
        vin: this.$route.params.vin,
        sessionId: this.$store.state.user.sessionId
          ? this.$store.state.user.sessionId
          : "",
      })
      .then(() => {
        if (this.$store.state.selectedCar.carDetailCard) {
          this.usedCarInfo = this.$store.getters.getMappedUsedCar;
          this.mapSpecifications();
        } else {
          this.$router.push({ name: "not-found" });
          this.usedCarInfo = null;
          this.isLoaded = true;
        }
      });
  },
  methods: {
    formatPriceNumber,
    formOsSubmit,
    toggleContract(i) {
      this.contracts[i] = !this.contracts[i];
      this.$forceUpdate();
    },
    alternativeClick(link) {
      this.usedCarInfo.mainImage = link.url;
    },
    contractClick(contract) {
      [
        ...this.usedCarInfo.contracts[2],
        ...this.usedCarInfo.contracts[3],
        ...this.usedCarInfo.contracts[4],
        ...this.usedCarInfo.contracts[5],
      ].forEach((element) => {
        if (element.contractName != contract.contractName) {
          document.getElementById(element.contractName).checked = false;
        } else {
          if (!document.getElementById(element.contractName).checked) {
            this.contractPrice = 0;
          } else {
            this.openToast(contract);
            this.contractPrice = contract.pvpValue;
          }
        }
      });
    },
    warrantyClick(contract) {
      this.usedCarInfo.warranty.forEach((el) => {
        if (el.label != contract.label) {
          document.getElementById(el.label).checked = false;
        } else {
          if (!document.getElementById(el.label).checked) {
            this.openToastRemove(contract);
            this.warrantyPrice = 0;
          } else {
            this.openToast(contract);
            this.warrantyPrice = contract.cost;
          }
        }
      });
    },
    openToastRemove(contract) {
      if (contract.type) {
        this.toastMessage =
          "Removido Contrato de Manutenção de " +
          contract.limitYearsText +
          " anos por " +
          contract.pvpText +
          " €";
      } else {
        this.toastMessage =
          "Removido " +
          contract.label +
          " por " +
          formatPriceNumber(contract.cost, "2", "€");
      }
      const x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
    openToast(contract) {
      if (contract.type) {
        this.toastMessage =
          "Adicionado Contrato de Manutenção de " +
          contract.limitYearsText +
          " anos por " +
          contract.pvpText +
          " €";
      } else {
        this.toastMessage =
          "Adicionado " +
          contract.label +
          " por " +
          formatPriceNumber(contract.cost, "2", "€");
      }
      const x = document.getElementById("toast");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
    closeToast() {
      const x = document.getElementById("toast");
      x.className = x.className.replace("show", "");
    },
    ctaAction(action) {
      let element;
      switch (action) {
        case "1":
          this.toggleTestDriveDisplay = true;
          setTimeout(() => {
            element = document.querySelector("#testDrive");
            element && element.scrollIntoView();
          }, 100);

          break;
        case "2":
          if (this.$store.state.user.loginToken) {
            this.toggleOSDisplay = true;
            setTimeout(() => {
              element = document.querySelector("#onlineSelling");
              element && element.scrollIntoView();
            }, 100);
          } else {
            this.$root.$emit("login");
          }
          break;
        case "-1":
          this.$router.push("/instalacoes");
          break;
      }
    },
    mapSpecifications() {
      this.usedCarInfo.specifications.specifications.forEach((ele) => {
        switch (ele.title) {
          case "version":
            ele.title = "Versão";
            break;
          case "power":
            ele.title = "Potência";
            break;
          case "exterior":
            ele.title = "Cor Exterior";
            break;
          case "interior":
            ele.title = "Interior";
            break;
          case "transmission":
            ele.title = "Transmissão";
            break;
          case "consumption":
            ele.title = "Consumo";
            break;
          default:
        }
      });
      this.usedCarInfo.specifications.specifications.unshift(
        {
          title: "Ano",
          description: this.usedCarInfo.year,
        },
        {
          title: "Quilómetros",
          description: this.usedCarInfo.kms,
        }
      );
      for (const el in this.usedCarInfo.contracts) {
        this.contracts[el] = false;
      }
      if (this.$route.hash == "#onlineSelling") {
        this.toggleOSDisplay = true;
        setTimeout(() => {
          let element = document.querySelector(this.$route.hash);
          element && element.scrollIntoView();
        }, 500);
      }
      this.isLoaded = true;
    },
    action(event) {
      switch (event) {
        case 0:
          if (this.$store.state.user.loginToken) {
            this.toggleOSDisplay = true;
          } else {
            this.$root.$emit("login");
          }

          break;
        case 1:
          this.toggleSimulatorDisplay = true;
          break;
        case 3:
          this.toggleTestDriveDisplay = true;
          break;
        default:
      }
    },
    toggle(index) {
      switch (index) {
        case 0:
          this.toggleSpec = !this.toggleSpec;
          this.$forceUpdate();
          break;
        case 2:
          this.toggleEquip = !this.toggleEquip;
          this.$forceUpdate();
          break;
      }
    },
    showLegal() {
      this.legal = !this.legal;
    },
    checkValues() {
      this.simulatorHasValues = true;
    },
    toogleFavorite() {
      if (this.user.id) {
        this.$store.dispatch("user/toggleFavorite", {
          vin: this.$route.params.vin,
          fid: this.usedCarInfo.fid,
        });
        this.isFavorite = !this.isFavorite;
      } else {
        this.$root.$emit("login");
      }
    },
    buildFormData() {
      this.usedCarInfoForm = {
        model: this.usedCarInfo.modelID,
        version: this.usedCarInfo.version,
        plate: this.usedCarInfo.plate,
        vin: this.usedCarInfo.vin,
        price: this.finalPrice,
        dealer: this.usedCarInfo.oidDealer,
        extra_property: this.usedCarInfo.extra_property,
      };
    },
    replaceBOVariable(text, variable, info) {
      return text.replaceAll(variable, info);
    },
    // reserve date == data de agora; endReserveDate == reserveDate + 10 dias
    formOSSubmit(data, paymentMethod) {
      let car = this.$store.state.selectedCar;
      let body = {
        delivery: "CUSTOM",
        fid: car.carDetailCard.financeId,
        reservationPayload: {
          dealershipId: car.companyId,
          deliveryDealer: car.dealerCode,
          endReserveDate: new Date(),
          finance: {
            capitalFinance: car.carDetailFinance.capitalFinance,
            creditType: 5,
            depositValue: car.carDetailFinance.depositValue,
            dtEndOfCampaign:
              car.carDetailCard.tooltipDescription.dtEndOfCampaign,
            financedValue: car.carDetailFinance.capitalFinance,
            initialExpenses: car.carDetailFinance.initialExpenses,
            lastFee: car.carDetailCard.tooltipDescription.lastFee,
            monthlyValue: car.carDetailFinance.monthlyPayment,
            mtic: car.carDetailFinance.ntic,
            nbrMonths: car.carDetailFinance.months,
            pricePvp: car.carDetailFinance.pvp,
            residualValue: car.carDetailFinance.residualValue,
            taeg: car.carDetailFinance.taeg,
            tan: car.carDetailFinance.tan,
          },
          pricePvp: car.carDetailCard.pricePvp,
          receipt: {
            address: "",
            location: "",
            nif: "",
            zipCode: "",
          },
          reserveDate: new Date(),
          reserveType: 1,
        },
        vin: this.usedCarInfo.vin,
        //financeId: car.carDetailCard.financeId,
        paymentDetails: {
          bookingNumber: "",
          buyerCar: {
            model: this.usedCarInfo.model,
            nrPlate: car.carDetailCard.licensePlate,
            purchaseType: "U",
            version: this.usedCarInfo.version,
            vin: this.usedCarInfo.vin,
          },
          client: data,
          district: this.$store.state.allInstallations.find(
            (el) => el.objectId == this.usedCarInfo.oidDealer
          )
            ? this.$store.state.allInstallations.find(
                (el) => el.objectId == this.usedCarInfo.oidDealer
              ).district
            : "",
          idCampaign: this.$store.state.isLexus ? 63 : 62,
          oidDealer: this.usedCarInfo.oidDealer,
          paymentMethod: paymentMethod,
          price: car.carDetailCard.pricePvp,
          receiveMarketingCommunication: "S",
          //passar isto para stg ou prod
          redirNOK: this.formOS.fields.find((el) => el.name == "redirPath")
            .value,
          redirNoCampaign: this.formOS.fields.find(
            (el) => el.name == "redirPath"
          ).value,
          redirOK:
            paymentMethod == "mbreference"
              ? location.origin + "/reserva"
              : this.formOS.fields.find((el) => el.name == "redirPath").value,
          fwCompleteTransactionEndpoint: location.origin + "/confirmacao",
          fwErrorVisaPage: location.origin + "/erro",
          fwOkVisaPage: location.origin + "/confirmacao",
          /*           fwOkVisaPage:
            "https://demoeasypay.rigorcg.pt/EasyPay/OnlineSellingServlet?cmd=redirectVisa", */
        },
      };
      this.formOsSubmit(body, this.$store.state.user.loginToken).then((res) => {
        if (paymentMethod != "mbreference") {
          window.open(
            res.data.redirect_url,
            "_blank",
            "toolbar=0,location=0,menubar=0"
          );
          this.$router.push("/reserva");
        } else {
          window.location.href =
            res.data.redirect_url + `&model=${this.usedCarInfo.version}`;
        }
      });
    },
  },
  watch: {},
};
</script>
<style lang="scss">
@import "../components/RangeList/Range.scss";
@import "../components/Heroes/Heroes.scss";
#toast {
  margin-top: 0;
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: $blackColor;
  color: $primaryWhiteColor;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 200;
  top: calc($menuHeight * 2);
  right: 0;
  font-size: 17px;
  @include respond-to-max("md") {
    display: flex;
    align-items: center;
    top: $menuHeight;
    font-size: $smallFontSize;
    max-width: 100%;
    span:first-child {
      word-wrap: normal;
    }
    span:nth-child(2) {
      margin-top: 0;
    }
  }
  span:first-child {
    padding-left: 1rem;
  }
  span:nth-child(2) {
    padding-right: 1rem;
    cursor: pointer;
    margin-left: 1rem;
  }
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    right: -30px;
    opacity: 0;
  }
  to {
    right: 0x;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    right: -30px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    right: 0;
    opacity: 1;
  }
  to {
    right: -30px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    right: 0;
    opacity: 1;
  }
  to {
    right: -30px;
    opacity: 0;
  }
}
</style>
